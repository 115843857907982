import React, { useEffect, useState } from "react"

import { Container, Row, Col, Card, CardBody, Label, Alert, Button, Input, FormFeedback, InputGroup, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import { connect } from "react-redux";
import { MDBDataTable } from "mdbreact"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import Spinners from "components/Common/Spinner"
import { useDispatch } from "react-redux";
import makeAnimated from "react-select/animated";
// import { Editor } from "react-draft-wysiwyg"
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import * as Yup from "yup";
import { useFormik } from "formik";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Link, useLocation } from "react-router-dom";
import { calculateDiscount, formatOption, getCurrentUser, getCurrentUserProfilePercent, trimAndAddEllipsis } from "helpers/functions";


import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Modal, Dropdown, Form, FormControl } from 'react-bootstrap'; // Import Bootstrap modal functionality


const Intro = (props) => {


  const animatedComponents = makeAnimated();
 
  const location = useLocation();
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(true)

  const [name, setName] = useState('');
  const [profileCompletion, setProfileCompletion] = useState(0);



  useEffect(() => {
    async function fetchData() {

        const storedName = getCurrentUser()?.first_name || "User";
        const storedProfileCompletion = getCurrentUserProfilePercent();

        if (storedName) {
            setName(storedName);
        }
        if (storedProfileCompletion) {
            setProfileCompletion(Number(storedProfileCompletion));
        }

        // get(url.GET_ALL_COURSES, {}, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

        //     setLoading(false)

        // }).catch((e) => {
        //     window.alert("Sorry!\n"+e)
        // }).finally(() => {
        //     setLoading(false)
        // });   
        
        setLoading(false)
      
    
    }
    fetchData();

    // Perform your componentDidMount logic here
   

  }, []); 



  return (
    <React.Fragment>
    
    <div className="container">
        <div className="row align-items-center">
            <div className="col-md-9">
                <div className="title">
                    <span><img src="/images/rate_ic.svg" alt="" /></span>
                    <h2 className="info">Welcome {name}</h2>
                </div>
            </div>
            <div className="col-md-3">
                <div className="label">Complete your Profile</div>
                <div className="progress_info">
                    <div className="progress" role="progressbar" aria-label="Animated striped" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100">
                        <div className="progress-bar progress-bar-striped progress-bar-animated" style={{width: `${profileCompletion}%`}}></div>
                    </div>
                    <div className="progress_time">
                        <small>{profileCompletion}%</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Intro);