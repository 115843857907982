import React, { useEffect, useState } from "react"

import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Button, Input, FormFeedback, InputGroup, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import { connect } from "react-redux";
import { MDBDataTable } from "mdbreact"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import Spinners from "components/Common/Spinner"
import { useDispatch } from "react-redux";
import makeAnimated from "react-select/animated";
// import { Editor } from "react-draft-wysiwyg"
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import * as Yup from "yup";
import { useFormik } from "formik";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Link } from "react-router-dom";
import { calculateDiscount, formatOption, randomNumber, setCurrentUserProfilePercentWithResponse, trimAndAddEllipsis } from "helpers/functions";


import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// import 'bootstrap-select/dist/css/bootstrap-select.min.css'; // Import Bootstrap Select CSS
import $ from 'jquery'; // Import jQuery
// import 'bootstrap-select'; // Import Bootstrap Select JS



// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-select/dist/css/bootstrap-select.min.css'; // Import the bootstrap-select CSS
import 'bootstrap-select/dist/js/bootstrap-select.min.js'; // Import the bootstrap-select JavaScript

import Header from "./Header";
import Footer from "./Footer";

const MyAnswer = (props) => {
    document.title = "My MyAnswer - Virtual Skills Platform";

    const animatedComponents = makeAnimated();

    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(true)
    const [isEditMode, setEditMode] = useState(false)
    const [questionaire, setQuestionaire] = useState([])

    // const [formValues, setFormValues] = useState({
    //     question_id: '1',
    //     selected_answer: "My family and social environment is supportive of my desire to undertake further study",
    // })
    const [formValues, setFormValues] = useState({})
    const QUESTIONS = [
        { label: "My current employment status is ", value: "1" },
        { label: "Question 2 ?", value: "2" },
        { label: "Question 3 ?", value: "3" }
    ]

    const DEFAULTANSWERS = [
        { label: "Unsure", value: "Unsure" },
        { label: "Yes", value: "Yes" },
        { label: "No", value: "No" }
    ]

    const [defaultQuestions, setDefaultQuestions] = useState([
        { question : "I have access to computers and other media devices", question_key : "questions_"+randomNumber(), defaultAnswer : { label : "Unsure", value : "Unsure" } },
        { question : "I do have a good internet connection", question_key : "questions_"+randomNumber(), defaultAnswer : { label : "Unsure", value : "Unsure" } },
        { question : "I know how to use the Internet and search for specific information", question_key : "questions_"+randomNumber(), defaultAnswer : { label : "Unsure", value : "Unsure" } },
        { question : "If I have connection or computer problems I can be easily helped", question_key : "questions_"+randomNumber(), defaultAnswer : { label : "Unsure", value : "Unsure" } },
        { question : "I have studied before through online learning or adult learning", question_key : "questions_"+randomNumber(), defaultAnswer : { label : "Unsure", value : "Unsure" } },
        { question : "I have recently online learned, including undertaking an online short course", question_key : "questions_"+randomNumber(), defaultAnswer : { label : "Unsure", value : "Unsure" } },
        { question : "I know how to use the Internet and search for specific information", question_key : "questions_"+randomNumber(), defaultAnswer : { label : "Unsure", value : "Unsure" } },
        { question : "If I have connection or computer problems I can be easily helped", question_key : "questions_"+randomNumber(), defaultAnswer : { label : "Unsure", value : "Unsure" } },
    ])
    
    const EMPLOYMENT_ANSWERS = [
        { label: "Full-Time Caregiver", value: "Full-Time Caregiver" },
        { label: "Part-Time Caregiver", value: "Part-Time Caregiver" },
        { label: "More", value: "More" }
    ]

    const validation = useFormik({
        // enableReinitialize : use this  flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: formValues,
        validationSchema: Yup.object({
            // question_id: Yup.object().required("Please enter question"),
            // selected_answer: Yup.string().required("Please enter answer"),
        }),
        onSubmit: async (values) => {
            // Handle form submission logic here

            setLoading(true);


            const questionsData = [];

            // Iterate through the keys in `values`
            Object.keys(values).forEach(key => {
            // Check if the key starts with "questions_*"
            if (key.startsWith('questions_')) {
                // If it matches, store the key-value pair in `removedValues`
                // questionsData[key] = values[key];

                let newKey = key.split("_")[1];

                let obj = { question_id : newKey, answer_id : values[key] }

                questionsData.push(obj);
                // Remove the key from the original `values` object
                // delete values[key];
            }
            });

            console.log("SUBMITTED",values,questionsData)






            // setTimeout(() => {
            //     setLoading(false)
            //     setEditMode(false)
            // }, 3000)

              post(url.CREATE_USER_ANSWERS, { questions : questionsData}, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {


                let message = response?.response || "";
                let responseData = response?.data || [];

                setCurrentUserProfilePercentWithResponse(response);

                window.alert("Success!\n"+message)
                // window.history.go(-1);
                setLoading(false)

              }).catch((e) => {
                window.alert("Sorry!\n"+e)
              }).finally(() => {
                setLoading(false)
              });   


        },
    });


    const handleAnswerSelect = (selectedOptions,key) => {
        validation.setFieldValue(key, selectedOptions);
    };


    useEffect(() => {
        async function fetchData() {

            get(url.GET_ALL_QUESTIONS, {}, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

                // console.log("QA ",response)

                let responseData = response.data;

                setCurrentUserProfilePercentWithResponse(response);
                
                loadDefaultQuestionsAnswers(responseData.questions,responseData.answers);
                

            }).catch((e) => {
                window.alert("Sorry!\n"+e)
                setLoading(false)
            }).finally(() => {
            });   

            // setLoading(false)


        }


        async function loadDefaultQuestionsAnswers(defaultQuestionsData,selectedAnswers) {

            // get(url.GET_ALL_COURSES, {}, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

            //     setLoading(false)

            // }).catch((e) => {
            //     window.alert("Sorry!\n"+e)
            // }).finally(() => {
            //     setLoading(false)
            // });   
            

            

            // let defaultFormValues = formValues

            // let questionObj = {}


            // defaultQuestionsData.map((data,i) => {
            //     questionObj[data.question_key] = data.defaultAnswer
            // })


            let questionObj = {}


            defaultQuestionsData.map((data,i) => {

                let isFoundAnswer = false 

                selectedAnswers.map((ans,j) => {

                    if(ans.question_id == data.question_id){

                        data.answers.map((qa,j) => {

                            if(ans.answer_id == qa.answer_id) {
                                questionObj["questions_"+data.question_id] = { label: qa.answer, value: qa.answer_id }
                                isFoundAnswer = true;
                                console.log("TRUE",ans,data)
                            }
                        })

                    }

                })

                // if(!isFoundAnswer) {
                //     questionObj["questions_"+data.question_id] = { label: data.answers[0].answer, value: data.answers[0].answer_id }
                // }

            })


            let questionMaster = []
            // console.log("D ",defaultQuestionsData)


            defaultQuestionsData.map((data,i) => {

                let answers = data.answers

                let answersList = [];
                answers.map((ans,j) => {
                    answersList.push({ label: ans.answer, value: ans.answer_id })
                })


                data.answers_list = answersList

                questionMaster.push(data)
            })

            // console.log("Q ",questionObj,questionMaster)
            setQuestionaire(questionMaster);
            setFormValues({ ...formValues, ...questionObj })
            setLoading(false)


            


        }

        
        
        fetchData();

        // Perform your componentDidMount logic here
        console.log('Component has mounted');

    }, []);



    const handleQuestionSelect = (selectedOptions) => {
        validation.setFieldValue('question_id', selectedOptions);
    };



    return (
        <React.Fragment>
            {/* isLoading ? <><Header /><section className="about_sec"><div className="container"><Spinners  /></div></section></> : */}
            { 
                isLoading ? <><Header /><section className="about_sec"><div className="container text-center"><Spinners  /></div></section></> :
                <>


                    <Header />


                    <section className="about_sec">
                        <Form className="needs-validation" onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                            <div className="container">


                                {/* <!-- About --> */}
                                <div className="about_main">
                                    <ul className="breadcrumb">
                                        <li><Link to="/dashboard" href="#" title="Dashboard">Dashboard</Link></li>
                                        <li><a href="#" title="About Me">About Me</a></li>
                                        <li>My Answer</li>
                                    </ul>
                                    <div className="row align-items-end justify-content-between mb-4 mb-xl-5 g-0">
                                        <div className="col-md-auto col-sm-12">
                                            <h3>My Answer</h3>
                                        </div>
                                        <div className="col-md-auto col-sm-12 text-end">
                                            {/* <a href="about_me_edit.html" className="btns gray"><img src="/images/edit_ic.svg" alt="" />Edit Profile</a> */}
                                            {isEditMode && <button type="submit" className="btns green mr-5">{isLoading ? <Spinners /> : <><img src="/images/check_ic.svg" alt="" />Save</>}</button>}
                                            {/* <Button className="btns green" color="primary" type="submit">
                                        Save
                                    </Button> */}
                                            {!isEditMode && <button type="button" onClick={() => setEditMode(true)} className="btns gray"><img src="/images/edit_ic.svg" alt="" />Edit Answer</button>}
                                        </div>
                                    </div>
                                    {/* <div className="about_block">
                                        
                                    </div> */}

                                <div className="row">
                                    { questionaire.map((data,i) => {

                                        // console.log("QUES ",validation.errors,validation.values)

                                        return <div key={i} className="col-md-6">
                                        <div className="information_item">
                                            <div className="col-md-9">
                                                <p>{data.user_question_label}</p>
                                            </div>
                                            <div className="col-md-2">
                                                {
                                                    isEditMode ?
                                                        <>
                                                            <Select
                                                                value={validation.values["questions_"+data.question_id]}
                                                                isMulti={false}
                                                                onChange={(selectedOptions) => handleAnswerSelect(selectedOptions,"questions_"+data.question_id) }
                                                                options={data.answers_list}
                                                                id={"questions_"+data.question_id}
                                                                className="removeborder"
                                                                classNamePrefix="selectpicker"
                                                                isValid={!validation.errors["questions_"+data.question_id] && validation.touched["questions_"+data.question_id]}
                                                            />
                                                            {validation.touched["questions_"+data.question_id] && validation.errors["questions_"+data.question_id] ? (
                                                                <div className="invalid-feedback">{validation.errors["questions_"+data.question_id]}</div>
                                                            ) : null}
                                                        </>
                                                        :
                                                        <h5 className="text-center">{validation.values?.["questions_"+data.question_id]?.label || ''}</h5>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    }) }
                                </div>

                                    {/* <div class="row">
                                            <div class="col-md-12">
                                                <div class="information_item">
                                                    <div class="col-md-9">
                                                        <p>My current employment status is</p>
                                                    </div>
                                                    <div class="col-md-2">
                                                        {
                                                            isEditMode ?
                                                                <>
                                                                    <Select
                                                                        value={validation.values.current_employment_status}
                                                                        isMulti={false}
                                                                        onChange={(selectedOptions) => handleAnswerSelect(selectedOptions,"current_employment_status") }
                                                                        options={EMPLOYMENT_ANSWERS}
                                                                        id="current_employment_status"
                                                                        className="removeborder"
                                                                        classNamePrefix="selectpicker"
                                                                        isValid={!validation.errors.current_employment_status && validation.touched.current_employment_status}
                                                                    />
                                                                    {validation.touched.current_employment_status && validation.errors.current_employment_status ? (
                                                                        <div className="invalid-feedback">{validation.errors.current_employment_status}</div>
                                                                    ) : null}
                                                                </>
                                                                :
                                                                <h5 className="text-center">{validation.values?.current_employment_status?.value || ''}</h5>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="information_item">
                                                    <div class="col-md-9">
                                                        <p>My family and social environment is supportive of my desire to undertake further study</p>
                                                    </div>
                                                    <div class="col-md-2">
                                                        {
                                                            isEditMode ?
                                                                <>
                                                                    <Select
                                                                        value={validation.values.answer_first}
                                                                        isMulti={false}
                                                                        onChange={(selectedOptions) => handleAnswerSelect(selectedOptions,"answer_first") }
                                                                        options={DEFAULTANSWERS}
                                                                        id="answer_first"
                                                                        className="removeborder"
                                                                        classNamePrefix="selectpicker"
                                                                        isValid={!validation.errors.answer_first && validation.touched.answer_first}
                                                                    />
                                                                    {validation.touched.answer_first && validation.errors.answer_first ? (
                                                                        <div className="invalid-feedback">{validation.errors.answer_first}</div>
                                                                    ) : null}
                                                                </>
                                                                :
                                                                <h5 className="text-center">{validation.values?.answer_first?.value || ''}</h5>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="information_item">
                                                    <div class="col-md-9">
                                                        <p>I think I can get hold of enough ‘free time’ in my life to commit to further studies</p>
                                                    </div>
                                                    <div class="col-md-2">
                                                        {
                                                            isEditMode ?
                                                                <>
                                                                    <Select
                                                                        value={validation.values.answer_second}
                                                                        isMulti={false}
                                                                        onChange={(selectedOptions) => handleAnswerSelect(selectedOptions,"answer_second") }
                                                                        options={DEFAULTANSWERS}
                                                                        id="answer_second"
                                                                        className="removeborder"
                                                                        classNamePrefix="selectpicker"
                                                                        isValid={!validation.errors.answer_second && validation.touched.answer_second}
                                                                    />
                                                                    {validation.touched.answer_second && validation.errors.answer_second ? (
                                                                        <div className="invalid-feedback">{validation.errors.answer_second}</div>
                                                                    ) : null}
                                                                </>
                                                                :
                                                                <h5 className="text-center">{validation.values?.answer_second?.value || ''}</h5>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="information_item">
                                                    <div class="col-md-9">
                                                        <p>I am good at working to deadlines and meeting them</p>
                                                    </div>
                                                    <div class="col-md-2">
                                                    {
                                                        isEditMode ?
                                                            <>
                                                                <Select
                                                                    value={validation.values.answer_third}
                                                                    isMulti={false}
                                                                    onChange={(selectedOptions) => handleAnswerSelect(selectedOptions,"answer_third") }
                                                                    options={DEFAULTANSWERS}
                                                                    id="answer_third"
                                                                    className="removeborder"
                                                                    classNamePrefix="selectpicker"
                                                                    isValid={!validation.errors.answer_third && validation.touched.answer_third}
                                                                />
                                                                {validation.touched.answer_third && validation.errors.answer_third ? (
                                                                    <div className="invalid-feedback">{validation.errors.answer_third}</div>
                                                                ) : null}
                                                            </>
                                                            :
                                                            <h5 className="text-center">{validation.values?.answer_third?.value || ''}</h5>
                                                    }
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="information_item">
                                                    <div class="col-md-9">
                                                        <p>I tend to persevere on tasks even when the work gets difficult</p>
                                                    </div>
                                                    <div class="col-md-2">
                                                        {
                                                            isEditMode ?
                                                                <>
                                                                    <Select
                                                                        value={validation.values.answer_fourth}
                                                                        isMulti={false}
                                                                        onChange={(selectedOptions) => handleAnswerSelect(selectedOptions,"answer_fourth") }
                                                                        options={DEFAULTANSWERS}
                                                                        id="answer_fourth"
                                                                        className="removeborder"
                                                                        classNamePrefix="selectpicker"
                                                                        isValid={!validation.errors.answer_fourth && validation.touched.answer_fourth}
                                                                    />
                                                                    {validation.touched.answer_fourth && validation.errors.answer_fourth ? (
                                                                        <div className="invalid-feedback">{validation.errors.answer_fourth}</div>
                                                                    ) : null}
                                                                </>
                                                                :
                                                                <h5 className="text-center">{validation.values?.answer_fourth?.value || ''}</h5>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="information_item">
                                                    <div class="col-md-9">
                                                        <p>I like sharing tasks and being involved in collaborative assignments and projects</p>
                                                    </div>
                                                    <div class="col-md-2">
                                                        {
                                                            isEditMode ?
                                                                <>
                                                                    <Select
                                                                        value={validation.values.answer_fifth}
                                                                        isMulti={false}
                                                                        onChange={(selectedOptions) => handleAnswerSelect(selectedOptions,"answer_fifth") }
                                                                        options={DEFAULTANSWERS}
                                                                        id="answer_fifth"
                                                                        className="removeborder"
                                                                        classNamePrefix="selectpicker"
                                                                        isValid={!validation.errors.answer_fifth && validation.touched.answer_fifth}
                                                                    />
                                                                    {validation.touched.answer_fifth && validation.errors.answer_fifth ? (
                                                                        <div className="invalid-feedback">{validation.errors.answer_fifth}</div>
                                                                    ) : null}
                                                                </>
                                                                :
                                                                <h5 className="text-center">{validation.values?.answer_fifth?.value || ''}</h5>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="information_item">
                                                    <div class="col-md-9">
                                                    <p>I feel anxious when doing tests and exams</p>
                                                    </div>
                                                    <div class="col-md-2">
                                                        {
                                                            isEditMode ?
                                                                <>
                                                                    <Select
                                                                        value={validation.values.answer_sixth}
                                                                        isMulti={false}
                                                                        onChange={(selectedOptions) => handleAnswerSelect(selectedOptions,"answer_sixth") }
                                                                        options={DEFAULTANSWERS}
                                                                        id="answer_sixth"
                                                                        className="removeborder"
                                                                        classNamePrefix="selectpicker"
                                                                        isValid={!validation.errors.answer_sixth && validation.touched.answer_sixth}
                                                                    />
                                                                    {validation.touched.answer_sixth && validation.errors.answer_sixth ? (
                                                                        <div className="invalid-feedback">{validation.errors.answer_sixth}</div>
                                                                    ) : null}
                                                                </>
                                                                :
                                                                <h5 className="text-center">{validation.values?.answer_sixth?.value || ''}</h5>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                           

                                        </div> */}



                                </div>

                            </div>
                        </Form>
                    </section>


                    <Footer />
                </>
            }

        </React.Fragment>
    )
}

export default connect(null, { setBreadcrumbItems })(MyAnswer);