import React, { useEffect, useState } from "react"

import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Button, Input, FormFeedback, InputGroup, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import Select from "react-select";
import { connect } from "react-redux";
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import Spinners from "components/Common/Spinner"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'bootstrap-select/dist/css/bootstrap-select.min.css'; // Import the bootstrap-select CSS
import 'bootstrap-select/dist/js/bootstrap-select.min.js'; // Import the bootstrap-select JavaScript
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Header from "components/HorizontalLayout/Header";
import { get, post } from "helpers/api_helper";
import { API_BASE_URL, GET_SEARCH_RESULT, OPEN_COURSE_DETAIL, REDIRECT_TO_COURSE_URL } from "helpers/url_helper";
import { getCurrentUser, handleImageError, redirectToCourse } from "helpers/functions";

const CoursesList = (props) => {
    document.title = "Courses - Virtual Skills Platform";

    // const { q, c } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchQuery, setSearchQuery] = useState("");
    const [searchCategory, setSearchCategory] = useState("");
    const [courseList, setCourseList] = useState([])
    const [categorName, setCategoryName] = useState("");


    const [loading, setLoading] = useState(true);

    const navigate = useNavigate()

    const location = useLocation()


    useEffect(() => {

        // console.log("QC ",searchParams.get("q"))
        // Check if the 'editMode' parameter is present
        if (searchParams) {

            // let searchQ = searchParams.get("q")
            let searchC = searchParams.get("c")
            let searchCategoryName = searchParams.get("name")

            // setSearchQuery(searchParams.get("q"))
            setCategoryName(searchCategoryName)
            setLoading(true)
            

            async function getSearchResult() {

                let userInfo = getCurrentUser();

                let body = { 
                    c: searchC
                }

                if(userInfo != null) {
                    // console.log("USER INFO ",userInfo)
                    body.user_id = userInfo.user_id
                } else {
                    // console.log("USER INFO BLANK" )
                }

                // get(GET_SEARCH_RESULT+`?c=${searchC}&q=${searchQ}`, {}, { headers: { credentials: 'include', Accept: "*/*" } }).then((response) => {
                post(GET_SEARCH_RESULT, body, { headers: { credentials: 'include', Accept: "*/*" } }).then((response) => {

                //   console.log("FETCHED ", response)

                  let responseData = response?.data || [];

                  setCourseList(responseData)

                  
                  setLoading(false)

                }).catch((e) => {
                  window.alert("Sorry!\n" + e)
                  setLoading(false)
                }).finally(() => {
                //   setLoading(false)
                });

            }
            getSearchResult();


            // setLoading(false)
            // setTimeout(() => {
            // },10000)
        } else {
          setLoading(false)
            // setTimeout(() => {
            //     setLoading(false)
            // },10000)
        }
    }, [searchParams]);

    const stripHtmlTags = (str) => {
        return str.replace(/<\/?[^>]+(>|$)/g, "");
    }

    // const redirectToCourse = (item) => {
        
    //     // if(searchItem.course_link) {
    //     //     return false;
    //     // }

    //     let userInfo = getCurrentUser();

    //     let body = {
    //         course_link : item?.course_link || "#",
    //         courses_id : item.courses_id
    //     }

    //     if(userInfo != null) {
    //         // console.log("USER INFO ",userInfo)
    //         body.user_id = userInfo.user_id
    //     } else {
    //         // console.log("USER INFO BLANK" )
    //     }

    //     post(REDIRECT_TO_COURSE_URL, body, { headers: { credentials: 'include', Accept: "*/*" } }).then((response) => {

    //     //   console.log("FETCHED ", response)

    //         let redirectUrl = response?.data || "#";

    //         // const { redirectUrl } = response.data;

    //         // Redirect to the new page in a new tab
    //         window.open(redirectUrl, '_blank');

            
    //         // setLoading(false)

    //     }).catch((e) => {
    //         window.alert("Sorry!\n" + e)
    //         // setLoading(false)
    //     }).finally(() => {
    //     //   setLoading(false)
    //     });
    // }

    return (
        <React.Fragment>
            {
                loading ? <><section className="about_sec"><div className="container mt-30 text-center"><Spinners  /></div></section></> :
                <>

                    <section className="container" id="category_section" style={{ backgroundColor: "white" }}>

                        <div class="job_info">
                            {/* <div class="input-group">
                                <span class="input-group-text"><img src="images/search_ic.svg" alt="" />

                                </span>
                                <input type="text" class="form-control" placeholder="Search for a Course?" />
                            </div> */}
                            {/* <h3 className="mb-30">{courseList.length} results for “{categorName}”</h3> */}
                            <h3 className="mb-20">{categorName || "Total Courses"} ({courseList.length})</h3>
                            


                            {courseList.length > 0 ? 
                                <section className="category_sec" id="category_section" style={{backgroundColor:"white", padding : "25px 0px 26px"}}>
                                
                                <div className="row">
                                {
                                    courseList.map((course,i) => {

                                        return(<div className="col-lg-3 col-md-3 col-sm-6" style={{  }}>
                                                <div className="category_box" style={{ borderStyle : 'solid', borderWidth : 1, borderColor: "#ccc", }}>
                                                    <div className="inn">
                                                        {/* <img style={{ marginBottom : 20 }} src="../images/course_img_2.jpg" alt="" /> */}
                                                        <div className="custom-image-container">
                                                            <img style={{ marginBottom : 0 }} onError={handleImageError} src={API_BASE_URL+course?.institute_detail?.image || "#"} alt="" /></div>
                                                        
                                                        <h3 className="h3-custom clamp-text-2">{course?.title}</h3>
                                                        <span className="clamp-text-1">{course?.institute_detail?.name}</span>
                                                        <p className="clamp-text-1" style={{ margin : 0 }}>{course?.category_detail?.name}</p>
                                                    </div>
                                                    <button type="button" onClick={() => redirectToCourse(course)}  class="btns gray mt-20">View Course</button>
                                                </div>
                                        </div>)


                                        return(
                                            <div class="job_block job-block-custom">
                                                <div class="job_item">
                                                    <div class="icon"><img onError={handleImageError} src={API_BASE_URL+course?.institute_detail?.image || "#"} alt="" /></div>
                                                    <div class="detail">
                                                        
                                                        {/* OTHER UI
                                                        <p>{course?.institute_detail?.name || "-"}</p>
                                                        <h5 style={{ marginBottom : 0 }}>{course?.title || ""}</h5>
                                                        <p style={{ marginTop : 10}}>Category: <small className="clamp-text">{course?.category_detail?.name || "-"}</small></p>
                                                        <p style={{ marginTop : 10}}>Description: <small className="clamp-text">{stripHtmlTags(course?.description || "")}</small></p> */}

                                                        <h5 style={{ marginBottom : 0 }}>{course?.title || ""}</h5>
                                                        <p>{course?.institute_detail?.name || "-"}</p>
                                                        <small className="clamp-text" style={{ marginTop : 10 }}>{stripHtmlTags(course?.description || "")}</small>
                                                    </div>
                                                </div>
                                                <div class="btn_info">
                                                    {/* <a target ="_blank" href={course?.course_link || "#"} class="btns gray">View Course Detail</a> */}
                                                    <button type="button" onClick={() => redirectToCourse(course)}  class="btns gray">View Course Detail</button>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                </div>
                                
                                </section>
                            :
                            <div className="info text-default">No result found</div>
                            }
                            



                        </div>


                    </section>


                </>
            }

        </React.Fragment>
    )
}

export default connect(null, { setBreadcrumbItems })(CoursesList);








