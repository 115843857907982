// Import the functions you need from the SDKs you need

// import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

const firebaseConfig = {

  apiKey: "AIzaSyDXdT8bjj669rYhsIIApC_zN5IvU57GkRk",

  authDomain: "bcp-vsp-test.firebaseapp.com",

  projectId: "bcp-vsp-test",

  storageBucket: "bcp-vsp-test.appspot.com",

  messagingSenderId: "475616039820",

  appId: "1:475616039820:web:cfdacd67458f57a26dfca9"

};

export default firebaseConfig;
// Initialize Firebase

// const app = initializeApp(firebaseConfig);