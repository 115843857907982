import React, { useEffect, useState } from "react"

import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Button, Input, FormFeedback, InputGroup, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import { connect, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact"
//Import Action to copy breadcrumb items from local state to redux state
// import { setBreadcrumbItems, setLoginModalState, setCategoryList, getCategoryList } from "../../store/actions";
import { setBreadcrumbItems, setLoginModalState } from "../../store/actions";

import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import Spinners from "components/Common/Spinner"
import { useDispatch } from "react-redux";
import makeAnimated from "react-select/animated";
// import { Editor } from "react-draft-wysiwyg"
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import * as Yup from "yup";
import { useFormik } from "formik";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Link, useNavigate } from "react-router-dom";
import { createSelector } from "reselect"

import { calculateDiscount, formatOption, getCurrentUser, handleImageError, setFooterCourses, trimAndAddEllipsis } from "helpers/functions";


import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


const options = {
    items: 12, // Set the number of items to display at a time
    loop: true,
    margin: 28,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
  };

const Home = (props) => {
  document.title = "Home - Virtual Skills Platform";

  const navigate = useNavigate();

  const animatedComponents = makeAnimated();
//   const categoryListState = useSelector((state) => {
//     console.log("S ",state?.Profile?.category_list || [])

//     return state?.Profile?.category_list || []
//   })
 
//   const handleLoginClose = (res) => {

//     setLoginModalLoading(false)

//     dispatch(setLoginModalState(false))
//   };
  const handleLoginShow = () => {

    // console.log(categoryListState)
    
    if(getCurrentUser() == null) {
        dispatch(setLoginModalState(true))
    }
  };

  const dispatch = useDispatch()
  const [loginModalLoading, setLoginModalLoading] = useState(true)
  const [isLoading, setLoading] = useState(true)
  const [categories, setCategories] = useState([])
  const [categoryLoading, setCategoryLoading] = useState(true)

//   const ProfileState = state => state
//   const categoryListProperties = createSelector(ProfileState, State => ({
//     list: State
//   }))

//   console.log("LIST",categoryListProperties)

  



  
  useEffect(() => {
    
  })


  useEffect(() => {
    async function fetchData() {

        // setCategoryLoading(true)

        get(url.GET_COURSE_CATEGORIES, {}, { headers : {credentials: 'include', Accept : "*/*"}}).then((response) => {

            let responseData = response.data;

            setCategories(responseData?.categories || [])

            localStorage.setItem("categoryList", JSON.stringify(responseData?.categories || []));
            setFooterCourses(responseData?.courses || []);
            // dispatch(setCategoryList(responseData))
            console.log("RES",response)
            setLoading(false)

        }).catch((e) => {
            window.alert("Sorry!\n"+e)
            setLoading(false)    
        }).finally(() => {
        });   
        
        // setLoading(false)
      
    
    }
    fetchData();

    // Perform your componentDidMount logic here
    console.log('Component has mounted');

  }, []); 



  const gotToCourseList = (e,c) => {
    e.preventDefault()
    console.log("C ",c)

    let categoryName = c.catName == null || c.catName == "" ? "" : c.catName
    let categoryId = c.catID == null || c.catID == "" ? "" : c.catID

    // console.log("EVENT ",`/search-results?q=${query}&c=${selectedCategory}`)
    navigate(`/course-list?c=${categoryId}&name=${categoryName}`);
    
  };

  const openDashboard = () => {
    
    navigate(`/dashboard`);
  }

  return (
    <React.Fragment>
    
    {/* { isLoading ? <Spinners  /> : */}
    { isLoading ? <><section className="about_sec"><div className="container mt-30 text-center"><Spinners  /></div></section></> :
      <>
                    
            {/* <!-- Hero --> */}
            <section className="hero_sec">
                <div className="container">
                    <div className="inner">
                        <img src="images/home_page_banner.png" alt="" />
                        <div className="cap_info">
                            <div className="detail">
                                <h1><small>Empowering your future</small>Elevate your skills and career</h1>
                                {/* <p>Our AI-based Virtual Skills Platform will take you to your next career stage by upskilling yourself with our partners Bournemouth-based training programmes and by helping you find your next career step</p> */}
                                <p>Leap to your next career stage by upskilling with our local training partners in Bournemouth and Poole.</p>
                                <button type="button" onClick={getCurrentUser() == null ? handleLoginShow : openDashboard} className="btns">Get Started</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- Plateform --> */}
            {/* <section className="plateform_sec">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-xl-3 col-lg-12">
                            <div className="detail">
                                <h2 className="global_title"><span>Our</span> Platform</h2>
                                <div className="info">Will be an amazing tool to:</div>
                                <div className="text-left">
                                    <a href="#" className="watch_link"><i className="fa fa-play"></i>Watch Video</a>
                                </div>
                                <button type="button" onClick={() => dispatch(setLoginModalState(true))} className="btns">Learn More</button>
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                            <div className="plat_block">
                                <figure><img src="images/plateform_ic_1.svg" alt="" /></figure>
                                <h3>Upskill</h3>
                                <p>Specialised Training to upskill themselves and become better qualified for job roles. </p>
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                            <div className="plat_block blue">
                                <figure><img src="images/plateform_ic_2.svg" alt="" /></figure>
                                <h3>Define your pathway</h3>
                                <p>A training path aligned with their background and goals to enhance qualifications.</p>
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                            <div className="plat_block green">
                                <figure><img src="images/plateform_ic_3.svg" alt="" /></figure>
                                <h3>Achieve your goals</h3>
                                <p>A potential career path based on their goals, background, future training and qualifications.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <!-- Course --> */}
            <section className="course_sec mt-50">
                <div className="container">
                    {/* <h2 className="global_title text-center">Explore Inspiring Online Courses</h2> */}
                    <h2 className="global_title text-center" style={{ marginBottom : 50,}}>Check our training partner’s latest courses and programmes</h2>
                    {/* <div className="info">Lorem ipsum dolor sit amet, consectetuer elitaenean Quisque rutrum aenean</div> */}
                    {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="tab-1" data-bs-toggle="tab" data-bs-target="#tab1" type="button" role="tab" aria-controls="tab1" aria-selected="true">UI/UX Design</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="tab-2" data-bs-toggle="tab" data-bs-target="#tab2" type="button" role="tab" aria-controls="tab2" aria-selected="false">Web Development</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="tab-3" data-bs-toggle="tab" data-bs-target="#tab3" type="button" role="tab" aria-controls="tab3" aria-selected="false">Data Analyst</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="tab-4" data-bs-toggle="tab" data-bs-target="#tab4" type="button" role="tab" aria-controls="tab4" aria-selected="false">Cloud Computing</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="tab-5" data-bs-toggle="tab" data-bs-target="#tab5" type="button" role="tab" aria-controls="tab5" aria-selected="false">Ethical Hacking</button>
                        </li>
                    </ul> */}
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab-1">
                            <div className="row gx-5">
                                <div className="col-sm-6 col-lg-4">
                                    <div className="course_block course_block-fixed-h">
                                        <figure><a href="https://bournemouth.ac.uk/" target="_blank"><img src="images/colleges/bu.png" alt="" /></a></figure>
                                        <div className="detail">
                                            <a href="https://bournemouth.ac.uk/" target="_blank"><i className="course_logo"><img src="images/about_logo_1.png" alt="" /></i></a>
                                            <h3><a href="https://bournemouth.ac.uk/" target="_blank">Bournemouth University</a></h3>
                                            <p>To inspire learning; to advance knowledge; to enrich society. That’s our purpose, and we’re proud of the contribution we make to the world around us. Whatever your time with us has in store for you, it’ll be an exciting journey – from meeting lifelong friends in halls, to your experience on placement, exploring the local area or joining a club or society. Find out more about what life at BU has to offer.</p>
                                            {/* <div className="rating">
                                                <strong>5</strong>
                                                <div className="rate"><i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i></div>
                                                (1550)
                                            </div> */}
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="col-sm-6 col-lg-4">
                                    <div className="course_block">
                                        <figure><a href="#"><img src="images/course_img_2.jpg" alt="" /></a></figure>
                                        <div className="detail">
                                            <i className="course_logo"><img src="images/course_logo_2.png" alt="" /></i>
                                            <h3><a href="#">Skills &amp; Learning</a></h3>
                                            <p>Lorem ipsum dolor sit ametolil col consectetur adipiscing.</p>
                                            <div className="rating">
                                                <strong>5</strong>
                                                <div className="rate"><i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i></div>
                                                (1550)
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                <div className="col-sm-6 col-lg-4">
                                    <div className="course_block course_block-fixed-h">
                                        <figure><a href="https://aub.ac.uk/" target="_blank"><img src="images/colleges/aub.png" alt="" /></a></figure>
                                        <div className="detail">
                                            <a href="https://aub.ac.uk/" target="_blank"><i className="course_logo"><img src="images/about_logo_2.png" alt="" /></i></a>
                                            <h3><a href="https://aub.ac.uk/" target="_blank">Arts University Bournemouth</a></h3>
                                            <p>Arts University Bournemouth is a further and higher education university based in Poole, England, specialising in art, performance, design, and media. It was formerly known as The Arts University College at Bournemouth and The Arts Institute at Bournemouth and is the home of Bournemouth Film School. Their motto is: Nurturing creativity. Engaging communities. Collaborating with industry. Making a difference.</p>
                                            {/* <div className="rating">
                                                <strong>5</strong>
                                                <div className="rate"><i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i></div>
                                                (1550)
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                {/* https://www.thecollege.co.uk/ */}
                                <div className="col-sm-6 col-lg-4">
                                    <div className="course_block course_block-fixed-h">
                                        <figure><a href="https://www.thecollege.co.uk/" target="_blank"><img src="images/colleges/thecollege.png" alt="" /></a></figure>
                                        <div className="detail">
                                            <a href="https://www.thecollege.co.uk/" target="_blank"><i className="course_logo"><img src="images/about_logo_4.png" alt="" /></i></a>
                                            <h3><a href="https://www.thecollege.co.uk/" target="_blank">Bournemouth and Poole College</a></h3>
                                            <p>The Bournemouth and Poole College is a well-established educational provider which delivers further education, higher education, and community-based courses in Bournemouth and in Poole on the south coast of England. It is one of the larger British colleges with thousands of learners each year.</p>
                                            {/* <div className="rating">
                                                <strong>5</strong>
                                                <div className="rate"><i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i></div>
                                                (1550)
                                            </div> */}
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="col-sm-6 col-lg-3">
                                    <div className="course_block">
                                        <figure><a href="#"><img src="images/course_img_4.jpg" alt="" /></a></figure>
                                        <div className="detail">
                                            <i className="course_logo"><img src="images/course_logo_4.png" alt="" /></i>
                                            <h3><a href="#">Lorem ipsum dolor sit amet</a></h3>
                                            <p>Lorem ipsum dolor sit ametolil col consectetur adipiscing.</p>
                                            <div className="rating">
                                                <strong>5</strong>
                                                <div className="rate"><i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i></div>
                                                (1550)
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>

                        
                    </div>
                    <div className="text-center">
                        <a href="#category_section" className="btns white">View All Courses</a>
                    </div>
                </div>
            </section>

            {/* <!-- Partner --> */}
            {/* <section className="partner_sec">
                <div className="container">
                    <h2 className="global_title">Our Top-Notch University Partners</h2>
                    <div className="partner_logo">
                        <img src="images/partner_bg.jpg" className="bg" alt="" />
                        <div className="owl-carousel PartnerSlider">
                            <div className="item">
                                <figure><img src="images/partner_logo_1.png" alt="" /></figure>
                            </div>
                            <div className="item">
                                <figure><img src="images/partner_logo_2.png" alt="" /></figure>
                            </div>
                            <div className="item">
                                <figure><img src="images/partner_logo_3.png" alt="" /></figure>
                            </div>
                            <div className="item">
                                <figure><img src="images/partner_logo_4.png" alt="" /></figure>
                            </div>
                            <div className="item">
                                <figure><img src="images/partner_logo_5.png" alt="" /></figure>
                            </div>
                        </div>
                    </div>
                    <h5>Learn from Our Leading Institutions</h5>
                    <p>we provide wealth of knowledge with a diverse and enriching educational experience.</p>
                </div>
            </section> */}

            {/* <!-- Category --> */}
            <section className="category_sec" id="category_section">
                <div className="container">
                    <h2 className="global_title text-center text-white mb-1">What would you like to learn?</h2>
                    <div className="info text-white">Choose a category to see the courses available locally</div>
                    <div className="row">
                       { categories.length > 0 ?
                            categories.map((category,index) => {
                                // console.log("IMG ",category)
                                return(
                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                        <a onClick={(e) => gotToCourseList(e,{ catName: category?.category_name || "", catID : category?.category_id || null})} href="#" className="category_box">
                                            <div className="inn">
                                                {/* <figure><img src="images/category_img1.svg" alt="" /></figure> */}
                                                <figure><img src={url.API_BASE_URL+category.category_image} alt={category?.category_name || ""}  onError={handleImageError} /></figure>
                                                <h3 className="category-title">{category?.category_name || ""}</h3>
                                                {/* <p>Lorem ipsum dolor sit amet consectetuer eget </p> */}
                                                <span>{category.course_count} Courses</span>
                                            </div>
                                        </a>
                                    </div>
                                )
                            })
                            :
                            <div className="info text-white">No category found</div>
                        }
                    </div>
                </div>
            </section>

            {/* <!-- Job --> */}
            <section className="job_sec">
                <div className="container">
                    <h2 className="global_title text-center">Unlock job opportunities aligned with your<br />training and career path</h2>
                    {/* <h2 className="global_title text-center mb-50">Find out what local opportunities best align to your current and future skills and draw your career pathway to achieve your professional goals</h2> */}
                    <div className="info">Find local opportunities aligned to your current and future skills - draw your career pathway to achieve your professional goals!</div>
                    <figure><img src="images/job_img.jpg" alt="" /></figure>
                    <div className="text-center">
                        <a href="#" className="btns">See How it Works</a>
                    </div>
                </div>
            </section>

            {/* <!-- Everyday --> */}
            <section className="everyday_sec">
                <div className="container">
                    <h2 className="global_title text-white text-center">Success stories everyday</h2>
                    {/* <div className="info text-white">Our users tell us about how the Virtual Skills Platform changed their lives helping them achieving their short and long-term professional goals</div> */}
                    <div className="info text-white">Our users tell us about how the Virtual Skills Platform helped them achieving their short and long-term professional goals</div>

                    {/* <OwlCarousel className="owl-carousel EverydayCarousel" {...options}> */}
                    <OwlCarousel className="owl-carousel EverydayCarousel" loop margin={28}>
                    {/* <div className="owl-carousel EverydayCarousel"> */}
                        <div className="item">
                            <div className="everyday_item">
                                <div className="inn">
                                    <figure><img src="images/everyday_img1.png" alt="" /></figure>
                                    <h3>“An amazing service”</h3>
                                    <p>Lorem ipsum dolor sit ametolil col consectetur adipiscing lectus a nunc mauris scelerisque sed egestas.</p>
                                    <div className="author">
                                        <strong>John Carter</strong>
                                        <span>Designer at Facebook</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="everyday_item">
                                <div className="inn">
                                    <figure><img src="images/everyday_img2.png" alt="" /></figure>
                                    <h3>“One of a kind service”</h3>
                                    <p>Ultrices eros in cursus turpis massa tincidunt sem nulla pharetra diam sit amet nisl suscipit adipis.</p>
                                    <div className="author">
                                        <strong>Sophie Moore</strong>
                                        <span>Head of Design at Uber</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="everyday_item">
                                <div className="inn">
                                    <figure><img src="images/everyday_img3.png" alt="" /></figure>
                                    <h3>“The best service”</h3>
                                    <p>Convallis posuere morbi leo urna molestie at elementum eu facilisis sapien pellentesque habitant.</p>
                                    <div className="author">
                                        <strong>Andy Smith</strong>
                                        <span>Developer at Google</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="everyday_item">
                                <div className="inn">
                                    <figure><img src="images/everyday_img1.png" alt="" /></figure>
                                    <h3>“An amazing service”</h3>
                                    <p>Lorem ipsum dolor sit ametolil col consectetur adipiscing lectus a nunc mauris scelerisque sed egestas.</p>
                                    <div className="author">
                                        <strong>John Carter</strong>
                                        <span>Designer at Facebook</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="everyday_item">
                                <div className="inn">
                                    <figure><img src="images/everyday_img2.png" alt="" /></figure>
                                    <h3>“One of a kind service”</h3>
                                    <p>Ultrices eros in cursus turpis massa tincidunt sem nulla pharetra diam sit amet nisl suscipit adipis.</p>
                                    <div className="author">
                                        <strong>Sophie Moore</strong>
                                        <span>Head of Design at Uber</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="everyday_item">
                                <div className="inn">
                                    <figure><img src="images/everyday_img3.png" alt="" /></figure>
                                    <h3>“The best service”</h3>
                                    <p>Convallis posuere morbi leo urna molestie at elementum eu facilisis sapien pellentesque habitant.</p>
                                    <div className="author">
                                        <strong>Andy Smith</strong>
                                        <span>Developer at Google</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="everyday_item">
                                <div className="inn">
                                    <figure><img src="images/everyday_img1.png" alt="" /></figure>
                                    <h3>“An amazing service”</h3>
                                    <p>Lorem ipsum dolor sit ametolil col consectetur adipiscing lectus a nunc mauris scelerisque sed egestas.</p>
                                    <div className="author">
                                        <strong>John Carter</strong>
                                        <span>Designer at Facebook</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="everyday_item">
                                <div className="inn">
                                    <figure><img src="images/everyday_img2.png" alt="" /></figure>
                                    <h3>“One of a kind service”</h3>
                                    <p>Ultrices eros in cursus turpis massa tincidunt sem nulla pharetra diam sit amet nisl suscipit adipis.</p>
                                    <div className="author">
                                        <strong>Sophie Moore</strong>
                                        <span>Head of Design at Uber</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="everyday_item">
                                <div className="inn">
                                    <figure><img src="images/everyday_img3.png" alt="" /></figure>
                                    <h3>“The best service”</h3>
                                    <p>Convallis posuere morbi leo urna molestie at elementum eu facilisis sapien pellentesque habitant.</p>
                                    <div className="author">
                                        <strong>Andy Smith</strong>
                                        <span>Developer at Google</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="everyday_item">
                                <div className="inn">
                                    <figure><img src="images/everyday_img1.png" alt="" /></figure>
                                    <h3>“An amazing service”</h3>
                                    <p>Lorem ipsum dolor sit ametolil col consectetur adipiscing lectus a nunc mauris scelerisque sed egestas.</p>
                                    <div className="author">
                                        <strong>John Carter</strong>
                                        <span>Designer at Facebook</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="everyday_item">
                                <div className="inn">
                                    <figure><img src="images/everyday_img2.png" alt="" /></figure>
                                    <h3>“One of a kind service”</h3>
                                    <p>Ultrices eros in cursus turpis massa tincidunt sem nulla pharetra diam sit amet nisl suscipit adipis.</p>
                                    <div className="author">
                                        <strong>Sophie Moore</strong>
                                        <span>Head of Design at Uber</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="everyday_item">
                                <div className="inn">
                                    <figure><img src="images/everyday_img3.png" alt="" /></figure>
                                    <h3>“The best service”</h3>
                                    <p>Convallis posuere morbi leo urna molestie at elementum eu facilisis sapien pellentesque habitant.</p>
                                    <div className="author">
                                        <strong>Andy Smith</strong>
                                        <span>Developer at Google</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/* </div> */}
                    </OwlCarousel>

                    {/* <div className="owl-carousel EverydayCarousel">
                        <div className="item">
                            <div className="everyday_item">
                                <div className="inn">
                                    <figure><img src="images/everyday_img1.png" alt="" /></figure>
                                    <h3>“An amazing service”</h3>
                                    <p>Lorem ipsum dolor sit ametolil col consectetur adipiscing lectus a nunc mauris scelerisque sed egestas.</p>
                                    <div className="author">
                                        <strong>John Carter</strong>
                                        <span>Designer at Facebook</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="everyday_item">
                                <div className="inn">
                                    <figure><img src="images/everyday_img2.png" alt="" /></figure>
                                    <h3>“One of a kind service”</h3>
                                    <p>Ultrices eros in cursus turpis massa tincidunt sem nulla pharetra diam sit amet nisl suscipit adipis.</p>
                                    <div className="author">
                                        <strong>Sophie Moore</strong>
                                        <span>Head of Design at Uber</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="everyday_item">
                                <div className="inn">
                                    <figure><img src="images/everyday_img3.png" alt="" /></figure>
                                    <h3>“The best service”</h3>
                                    <p>Convallis posuere morbi leo urna molestie at elementum eu facilisis sapien pellentesque habitant.</p>
                                    <div className="author">
                                        <strong>Andy Smith</strong>
                                        <span>Developer at Google</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="everyday_item">
                                <div className="inn">
                                    <figure><img src="images/everyday_img1.png" alt="" /></figure>
                                    <h3>“An amazing service”</h3>
                                    <p>Lorem ipsum dolor sit ametolil col consectetur adipiscing lectus a nunc mauris scelerisque sed egestas.</p>
                                    <div className="author">
                                        <strong>John Carter</strong>
                                        <span>Designer at Facebook</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="everyday_item">
                                <div className="inn">
                                    <figure><img src="images/everyday_img2.png" alt="" /></figure>
                                    <h3>“One of a kind service”</h3>
                                    <p>Ultrices eros in cursus turpis massa tincidunt sem nulla pharetra diam sit amet nisl suscipit adipis.</p>
                                    <div className="author">
                                        <strong>Sophie Moore</strong>
                                        <span>Head of Design at Uber</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="everyday_item">
                                <div className="inn">
                                    <figure><img src="images/everyday_img3.png" alt="" /></figure>
                                    <h3>“The best service”</h3>
                                    <p>Convallis posuere morbi leo urna molestie at elementum eu facilisis sapien pellentesque habitant.</p>
                                    <div className="author">
                                        <strong>Andy Smith</strong>
                                        <span>Developer at Google</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="everyday_item">
                                <div className="inn">
                                    <figure><img src="images/everyday_img1.png" alt="" /></figure>
                                    <h3>“An amazing service”</h3>
                                    <p>Lorem ipsum dolor sit ametolil col consectetur adipiscing lectus a nunc mauris scelerisque sed egestas.</p>
                                    <div className="author">
                                        <strong>John Carter</strong>
                                        <span>Designer at Facebook</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="everyday_item">
                                <div className="inn">
                                    <figure><img src="images/everyday_img2.png" alt="" /></figure>
                                    <h3>“One of a kind service”</h3>
                                    <p>Ultrices eros in cursus turpis massa tincidunt sem nulla pharetra diam sit amet nisl suscipit adipis.</p>
                                    <div className="author">
                                        <strong>Sophie Moore</strong>
                                        <span>Head of Design at Uber</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="everyday_item">
                                <div className="inn">
                                    <figure><img src="images/everyday_img3.png" alt="" /></figure>
                                    <h3>“The best service”</h3>
                                    <p>Convallis posuere morbi leo urna molestie at elementum eu facilisis sapien pellentesque habitant.</p>
                                    <div className="author">
                                        <strong>Andy Smith</strong>
                                        <span>Developer at Google</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="everyday_item">
                                <div className="inn">
                                    <figure><img src="images/everyday_img1.png" alt="" /></figure>
                                    <h3>“An amazing service”</h3>
                                    <p>Lorem ipsum dolor sit ametolil col consectetur adipiscing lectus a nunc mauris scelerisque sed egestas.</p>
                                    <div className="author">
                                        <strong>John Carter</strong>
                                        <span>Designer at Facebook</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="everyday_item">
                                <div className="inn">
                                    <figure><img src="images/everyday_img2.png" alt="" /></figure>
                                    <h3>“One of a kind service”</h3>
                                    <p>Ultrices eros in cursus turpis massa tincidunt sem nulla pharetra diam sit amet nisl suscipit adipis.</p>
                                    <div className="author">
                                        <strong>Sophie Moore</strong>
                                        <span>Head of Design at Uber</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="everyday_item">
                                <div className="inn">
                                    <figure><img src="images/everyday_img3.png" alt="" /></figure>
                                    <h3>“The best service”</h3>
                                    <p>Convallis posuere morbi leo urna molestie at elementum eu facilisis sapien pellentesque habitant.</p>
                                    <div className="author">
                                        <strong>Andy Smith</strong>
                                        <span>Developer at Google</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>

            {/* <!-- CTA --> */}
            <section className="cta_sec">
                <div className="container">
                    <div className="inner">
                        <img src="images/cta_bg.jpg" className="bg" alt="" />
                        <h3>GET STARTED TODAY</h3>
                        <h2 className="global_title text-white">Learn. Evolve. Achieve.</h2>
                        <button type="button" onClick={handleLoginShow} className="btns white">Start for Free</button>
                    </div>
                </div>
            </section>
      </>
    }
    
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Home);